import { useEffect, useCallback } from 'react'
import { Helpers } from '@api.stream/studio-kit'

const { useStudio, useDevices } = Helpers.React

const DeviceSelection = () => {
  const { webcamId, microphoneId, setWebcamId, setMicrophoneId } = useStudio()

  const devices = useDevices()

  useEffect(() => {
    if (!webcamId) setWebcamId(devices.webcams[0]?.deviceId)
  }, [devices.webcams, setWebcamId, webcamId])

  useEffect(() => {
    if (!microphoneId) setMicrophoneId(devices.microphones[0]?.deviceId)
  }, [devices.microphones, microphoneId, setMicrophoneId])

  const handleDevicesChange = useCallback(
    updatedDevices => {
      if (!webcamId) setWebcamId(updatedDevices.webcams[0]?.deviceId)
      if (!microphoneId)
        setMicrophoneId(updatedDevices.microphones[0]?.deviceId)
    },
    [webcamId, setWebcamId, microphoneId, setMicrophoneId]
  )

  useEffect(() => {
    if (Helpers.DeviceManager) {
      Helpers.DeviceManager.onDevicesChanged(handleDevicesChange)
      return () => {
        Helpers.DeviceManager.offDevicesChanged(handleDevicesChange)
      }
    }
  }, [handleDevicesChange])

  return (
    <div>
      <div className='mt-4 flex items-center justify-between'>
        <label className='mr-4'>Webcam</label>
        <select
          value={webcamId == null ? '' : webcamId}
          onChange={e => {
            setWebcamId(e.target.value)
          }}
          className='max-w-[250px] rounded-xl px-4 py-2'
        >
          {devices.webcams.map(x => (
            <option key={x.deviceId} value={x.deviceId}>
              {x.label}
            </option>
          ))}
        </select>
      </div>

      <div className='mt-4 flex items-center justify-between'>
        <label className='mr-4'>Microphone</label>
        <select
          value={microphoneId == null ? '' : microphoneId}
          onChange={e => {
            setMicrophoneId(e.target.value)
            Helpers.Room.getUserMedia({ audio: true }).then(res =>
              console.log(res)
            )
          }}
          className='max-w-[250px] rounded-xl px-4 py-2'
        >
          {devices.microphones.map(x => (
            <option key={x.deviceId} value={x.deviceId}>
              {x.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default DeviceSelection

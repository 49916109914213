import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { TokenReducer } from './reducers/tokenReducers'

const initialState = {}
const rootReducer = combineReducers({
  token: TokenReducer,
})

export default createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(thunk))
)

import React from 'react'

const TestError = () => {
  return (
    <div className='flex h-[90vh] w-full items-center justify-center'>
      <button
        className='btn-secondary btn'
        onClick={() => methodDoesNotExist()}
      >
        Throw error
      </button>
    </div>
  )
}

export default TestError

import { Routes, Route, BrowserRouter } from 'react-router-dom'
import AppLayout from './layouts/AppLayout'
import { Provider } from 'react-redux'
import store from './store/store'
import './index.css'
import HostScreen from './pages/host/HostScreen'
import LoginScreen from './pages/login/LoginScreen'
import GuestScreen from './pages/guest/GuestScreen'
import TestError from './pages/test-error/TestError'
import HeapIdentify from './components/HeapIdentify'
import useLocalStorage from 'use-local-storage'
import { Toaster } from 'react-hot-toast'
import TechTest from './pages/TechTest'

function App() {
  const [identity] = useLocalStorage('heap-identity', null)

  return (
    <Provider store={store}>
      <BrowserRouter>
        {identity ? (
          <AppLayout>
            <Routes>
              <Route path='/' element={<LoginScreen />} />
              <Route path='/login' element={<LoginScreen />} />
              <Route path='/host' element={<HostScreen />} />
              <Route path='/test-error' element={<TestError />} />
              <Route path='/tech-test' element={<TechTest />} />
              <Route path='/guest' element={<GuestScreen />} />
            </Routes>
          </AppLayout>
        ) : (
          <HeapIdentify />
        )}
        <Toaster />
      </BrowserRouter>
    </Provider>
  )
}

export default App

import { useEffect, useRef } from 'react'
import toast from 'react-hot-toast'
import { twJoin } from 'tailwind-merge'
import useVonageStore from '../hooks/useVonage'
import { cn } from '../utils/lib'

interface VonageProps {
  sessionId: string
  isHost: boolean
  displayName: string
  className?: string
}

function Vonage({ sessionId, isHost, displayName, className }: VonageProps) {
  const browserSupportsVonage = useVonageStore(state => state.isCompatible)
  const vonageConnectionStatus = useVonageStore(state => state.connectionStatus)
  const initializeVonage = useVonageStore(state => state.initialize)
  const layout = useVonageStore(state => state.layout)
  const participants = useVonageStore(state => state.participants)
  const cameraAccessAllowed = useVonageStore(state => state.cameraAccessAllowed)
  const publisher = useVonageStore(state => state.publisher)

  const divHost = useRef(null)
  const divSelf = useRef(null)
  const divOther = useRef(null)
  const divOthersHostView = useRef(null)

  useEffect(() => {
    // wait for first render
    if (divHost === null || divSelf === null || divOther === null) return
    initializeVonage({
      userIsHost: isHost,
      sessionId,
      divHost: divHost.current,
      divSelf: divSelf.current,
      divOther: divOther.current,
      divOthersHostView: divOthersHostView.current,
      displayName,
      onFatalError: msg => toast.error(msg, { duration: 10000 }),
    })
      .then(() => {
        console.log(
          'Successfully initialized Vonage with useVonage in Vonage.jsx'
        )
        return
      })
      .catch(e => console.error('Failed to initialize Vonage:', e))
    return () => {
      if (publisher) {
        publisher.destroy()
      }
    }
  }, [divHost, divSelf, divOther])

  useEffect(() => {
    if (!cameraAccessAllowed) {
      toast.error('Please allow camera access and refresh.', {
        duration: 10000,
      })
    }
  }, [cameraAccessAllowed])

  return (
    <div className={cn('w-full', className)}>
      {browserSupportsVonage ? (
        <div
          className={twJoin(
            'flex flex-col gap-2',
            (layout === 'hidden' || vonageConnectionStatus !== 'connected') &&
              'hidden'
          )}
        >
          {/* Host */}
          <div
            className={`aspect-video w-full ${
              participants.some(p => p.isHost) ? '' : 'hidden'
            }`}
          >
            <div className='rounded-lg' ref={divHost} />
          </div>

          <div
            className={twJoin(
              `flex flex-row justify-between gap-2`,
              layout !== 'three' && 'hidden'
            )}
          >
            {/* Self */}
            <div
              className={twJoin(
                'aspect-video w-full',
                !cameraAccessAllowed && 'hidden'
              )}
            >
              <div className='rounded-lg' ref={divSelf} />
            </div>

            {/* Other participant */}
            <div
              className={twJoin(
                `aspect-video w-full`,
                participants.find(p => !p.isHost) === undefined && 'hidden'
              )}
            >
              <div className='rounded-lg' ref={divOther} />
            </div>
          </div>
          <div
            ref={divOthersHostView}
            className={twJoin(
              'flex flex-row justify-between',
              !isHost && 'hidden'
            )}
          />
        </div>
      ) : (
        <h1>
          Sorry, your browser does not support Tourable tours. Please try again
          on another device.
        </h1>
      )}
    </div>
  )
}

export default Vonage

import { useEffect, useState } from 'react'
import DeviceSelection from '../../components/DeviceSelection'

export const JoinForm = props => {
  const { setJoining, displayName, setDisplayName, ensureDevices } = props
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 2000)
  }, [])

  return (
    <div className='flex h-[90vh] w-full items-center justify-center'>
      <div>
        {ensureDevices === 'blocked' && (
          <div className='alert alert-warning mb-4'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-6 shrink-0 stroke-current'
              fill='none'
              viewBox='0 0 24 24'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
              />
            </svg>
            <span>
              Please allow webcam and microphone
              <br /> access, then refresh the page.
            </span>
          </div>
        )}
        {ensureDevices === 'pending' &&
          (isLoading ? (
            <div className='mb-4 flex items-center gap-3'>
              <span className='loading loading-spinner loading-md'></span>
              <p>Checking for webcam and microphone...</p>
            </div>
          ) : (
            <div className='mb-4'>
              <p>
                Please allow webcam and microphone access
                <br /> to join tour.
              </p>
            </div>
          ))}
        <div className='flex items-center justify-between'>
          <label className='mr-4'>Display name</label>
          <input
            className='rounded-xl px-4 py-2'
            type='text'
            autoFocus={true}
            defaultValue={displayName}
            onChange={e => {
              setDisplayName(e.target.value)
            }}
          />
        </div>
        {ensureDevices === 'allowed' && (
          <div>
            <DeviceSelection />
          </div>
        )}
        <div className='mt-8 flex justify-center'>
          <button
            className='btn-block btn border-transparent bg-[#F3F3F3] text-[#1e293b] shadow-[0_4px_4px_rgba(0,0,0,.25)] hover:border-transparent hover:bg-[#F3F3F3]'
            onClick={() => {
              setJoining(true)
            }}
            disabled={ensureDevices !== 'allowed'}
          >
            Join tour
          </button>
        </div>
        <p className='mt-3 text-center'>
          Try the{' '}
          <a href='/tech-test' className='underline'>
            tech test
          </a>{' '}
          before joining to ensure a smooth tour.
        </p>
      </div>
    </div>
  )
}

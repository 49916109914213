import { TbLayout, TbUsers } from 'react-icons/tb'
import {
  MdOutlineChat,
  MdOutlineMarkChatUnread,
  MdPictureInPicture,
} from 'react-icons/md'
import {
  BsFillCameraVideoFill,
  BsFillCameraVideoOffFill,
  BsFillMicFill,
  BsFillMicMuteFill,
} from 'react-icons/bs'
import { TbLayoutOff } from 'react-icons/tb'
import useVonageStore from '../../hooks/useVonage'

const ControlPanel = ({
  showChat,
  isUnread,
  showMembers,
  toggleChat,
  toggleMembers,
}) => {
  const isCameraEnabled = useVonageStore(state => state.isCameraEnabled)
  const isMicrophoneEnabled = useVonageStore(state => state.isMicrophoneEnabled)

  const layout = useVonageStore(state => state.layout) // "three" | "hostOnly" | "hidden"
  const toggleCamera = useVonageStore(state => state.toggleCamera)
  const toggleMicrophone = useVonageStore(state => state.toggleMicrophone)
  const toggleLayout = useVonageStore(state => state.toggleLayout)

  return (
    <>
      <div className='bg-0 pointer-events-auto flex items-center gap-2 rounded-xl px-4 py-2 sm:gap-5'>
        <button
          type='button'
          className={`flex h-[3.6rem] w-[3.6rem] items-center justify-center rounded-full bg-[#F3F3F3] shadow-[0_4px_4px_rgba(0,0,0,.75)]`}
          onClick={() => toggleCamera()}
        >
          {isCameraEnabled ? (
            <BsFillCameraVideoFill className='text-[#1e293b]' />
          ) : (
            <BsFillCameraVideoOffFill className='text-[#1e293b]' />
          )}
        </button>
        <button
          type='button'
          className={`flex h-[3.6rem] w-[3.6rem] items-center justify-center rounded-full bg-[#F3F3F3] shadow-[0_4px_4px_rgba(0,0,0,.75)]`}
          onClick={() => toggleMicrophone()}
        >
          {isMicrophoneEnabled ? (
            <BsFillMicFill className='text-[#1e293b]' />
          ) : (
            <BsFillMicMuteFill className='text-[#1e293b]' />
          )}
        </button>

        <button
          type='button'
          className={`flex h-[3.6rem] w-[3.6rem] items-center justify-center rounded-full ${
            showChat
              ? 'bg-[#8ab4f8]'
              : isUnread
              ? 'bg-[#ff4343]'
              : 'bg-[#F3F3F3]'
          } shadow-[0_4px_4px_rgba(0,0,0,.5)]`}
          onClick={toggleChat}
        >
          {showChat ? (
            <MdOutlineChat className='text-[1.6rem] text-[#535353]' />
          ) : isUnread ? (
            <MdOutlineMarkChatUnread className='text-[1.6rem] text-black' />
          ) : (
            <MdOutlineChat className='text-[1.6rem] text-[#1e293b]' />
          )}
        </button>
        <button
          type='button'
          className={`flex h-[3.6rem] w-[3.6rem] items-center justify-center rounded-full ${
            showMembers ? 'bg-[#8ab4f8]' : 'bg-[#F3F3F3]'
          } shadow-[0_4px_4px_rgba(0,0,0,.75)]`}
          onClick={toggleMembers}
        >
          <TbUsers
            className={`${
              showMembers ? 'text-[#535353]' : 'text-[#1e293b]'
            } text-[1.6rem]`}
          />
        </button>
        <button
          type='button'
          className={`flex h-[3.6rem] w-[3.6rem] items-center justify-center rounded-full bg-[#F3F3F3] shadow-[0_4px_4px_rgba(0,0,0,.75)]`}
          onClick={toggleLayout}
        >
          {layout === 'three' ? (
            <TbLayout className='h-[1.6rem] w-[1.6rem] -rotate-90 text-[#1e293b]' />
          ) : layout === 'hostOnly' ? (
            <MdPictureInPicture className='h-[1.6rem] w-[1.6rem] text-[#1e293b]' />
          ) : (
            <TbLayoutOff className='h-[1.6rem] w-[1.6rem] -rotate-90 text-[#1e293b]' />
          )}
        </button>
      </div>
    </>
  )
}

export default ControlPanel

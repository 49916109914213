import { Link } from 'react-router-dom'

const HostHeader = () => {
  return (
    <div className='flex w-full items-center justify-center bg-base-200 px-6 py-3'>
      <div className='max-w-8xl w-full'>
        <Link to='/' className='text-lg font-extrabold'>
          Tourable Tours
        </Link>
      </div>
    </div>
  )
}

export default HostHeader

export interface Message {
  displayName: string
  content: string
  sender: string
}

interface ChatMessageProps {
  message: Message
  isSelf: boolean
}

const ChatMessage = (props: ChatMessageProps) => {
  const { message, isSelf } = props

  return (
    <div className={`chat ${isSelf ? 'chat-end text-right' : 'chat-start'}`}>
      <div className='chat-header'>{message.displayName}</div>
      <div className='chat-bubble mt-1'>{message.content}</div>
    </div>
  )
}

export { ChatMessage }

import React, { useState, useEffect, useRef } from 'react'
import { Helpers } from '@api.stream/studio-kit'
import { Loader } from '@googlemaps/js-api-loader'
import ChatView from '../../components/chat/ChatView'
import ParticipantManager from './ParticipantManager'
import ControlPanel from './ControlPanel'
import GuestMap from './GuestMap'
import Vonage from '../../components/Vonage'

const { Room } = Helpers
const { useStudio } = Helpers.React

const loader = new Loader({
  apiKey: import.meta.env.VITE_APP_MAP_KEY,
  version: 'weekly',
})

const ProjectView = ({
  selfId,
  showMap,
  mapPos,
  vonageSessionId,
  displayName,
}) => {
  const { studio, project, room } = useStudio()
  const renderContainer = useRef()
  const [showChat, setShowChat] = useState(false)
  const [showMembers, setShowMembers] = useState(false)
  const [lastReadTimestamp, setLastReadTimestamp] = useState(0)
  const [chatHistory, setChatHistory] = useState([])
  const [mapStatus, setMapStatus] = useState(false)
  const latestTimestamp =
    chatHistory?.length > 0 ? chatHistory[chatHistory.length - 1]?.timestamp : 0
  const [draft, setDraft] = useState('')

  useEffect(() => {
    loader.load().then(() => {
      setMapStatus(true)
    })
  }, [])

  useEffect(() => {
    Room.ensureDevicePermissions()
  })

  useEffect(() => {
    if (room) {
      room.sendData({ type: 'UserJoined' })
    }
  }, [room])

  useEffect(() => {
    if (room) {
      room.useChatHistory(history => {
        setChatHistory(history)
      })
    }
  }, [room])

  useEffect(() => {
    if (showChat && chatHistory?.length > 0) {
      setLastReadTimestamp(chatHistory[chatHistory.length - 1]?.timestamp)
    }
  }, [showChat, chatHistory])

  useEffect(() => {
    if (!studio || !project) return
    studio.render({
      containerEl: renderContainer.current,
      projectId: project.id,
      dragAndDrop: false, // Disable controls for guests
    })
  }, [renderContainer, studio, project])

  const toggleChat = () => {
    setShowChat(!showChat)
    if (!showChat && chatHistory?.length > 0) {
      setLastReadTimestamp(latestTimestamp)
    }
    if (!showChat && showMembers) {
      toggleMembers()
    }
  }

  const toggleMembers = () => {
    setShowMembers(!showMembers)
    if (!showMembers && showChat) {
      toggleChat()
    }
  }

  const toggleHand = () => {
    throw Error('Unimplemented toggleHand')
  }

  if (!room) return null

  return (
    <div className='relative flex h-full w-full flex-col'>
      <div className='h-full'>
        {/* this div should sit on top of the render container */}
        <div className='absolute left-0 top-0 z-10 flex aspect-video h-full w-full items-center'>
          {/* this div should sit on top of the video within the render container */}
          <div className='absolute z-10 flex h-full w-full justify-end px-6 py-6'>
            {/* Explanation of calc usage: */}
            {/*   On small screens, the chatroom falls beneath the ControlPanel. */}
            {/*   Reduce the height of the chatroom so that it remains uncovered. */}
            {/*   Then go back to full-height when there's no more potential for overlap */}
            <section className='flex h-[calc(100%-5rem)] w-28 flex-col items-end gap-2 sm:w-56 md:w-72 min-[1100px]:h-full'>
              <Vonage
                className=''
                isHost={false}
                sessionId={vonageSessionId}
                displayName={displayName}
              />
              {showChat && (
                <ChatView
                  className='hidden flex-grow shadow-2xl md:flex'
                  chatHistory={chatHistory}
                  selfId={selfId}
                  onClose={toggleChat}
                  draft={draft}
                  setDraft={setDraft}
                />
              )}
              {showMembers && (
                <ParticipantManager
                  className='hidden flex-grow shadow-2xl md:flex'
                  onClose={toggleMembers}
                />
              )}
            </section>
          </div>
        </div>

        <div className='h-full w-full overflow-hidden'>
          {/* this is the render container, in which api.stream renders its shadow DOM */}
          <div
            className='aspect-video min-h-full min-w-full overflow-hidden'
            ref={renderContainer}
          />
        </div>
      </div>
      <div className='pointer-events-none absolute bottom-4 z-40 flex w-full justify-center'>
        <ControlPanel
          showChat={showChat}
          toggleChat={toggleChat}
          isUnread={lastReadTimestamp < latestTimestamp}
          showMembers={showMembers}
          toggleMembers={toggleMembers}
          toggleHand={toggleHand}
        />
      </div>
      {(showChat || showMembers) && (
        <dialog className='modal modal-open md:hidden'>
          <div className='flex h-full w-full flex-col items-end overflow-hidden'>
            {showChat && (
              <ChatView
                className='flex-grow rounded-none shadow-2xl'
                chatHistory={chatHistory}
                selfId={selfId}
                onClose={toggleChat}
                draft={draft}
                setDraft={setDraft}
              />
            )}
            {showMembers && (
              <ParticipantManager
                className='flex-grow rounded-none shadow-2xl'
                onClose={toggleMembers}
              />
            )}
          </div>
        </dialog>
      )}
    </div>
  )
}

export default ProjectView

import { useState } from 'react'
import NetworkTest from 'opentok-network-test-js'
import * as OT from '@opentok/client'
import axios from 'axios'
import { MdNetworkCheck } from 'react-icons/md'

// assuming we should expect a video call with this many participants
const expectedVonagePublishers = 5

/**
 * Given 1280x720p webcam settings per stream (via {@link useVonage})
 * See [Vonage Support](https://api.support.vonage.com/hc/en-us/articles/6646237236636-What-is-the-minimum-bandwidth-requirement-to-use-the-Vonage-Video-API-)
 */
const vonageMbpsDownPerTour = {
  minimum: 0.35 * expectedVonagePublishers,
  recommended: 1 * expectedVonagePublishers,
}

const apiStreamMbpsDownPerTour = {
  // based on the bitrate of the HLS conversions - see the mediaconvert template
  minimum: 5,
  recommended: 25,
}

const SUGGESTED_MBPS_DOWN_PER_TOUR = {
  minimum: apiStreamMbpsDownPerTour.minimum + vonageMbpsDownPerTour.minimum,
  recommended:
    apiStreamMbpsDownPerTour.recommended + vonageMbpsDownPerTour.recommended,
}

type ConnectivityError = {
  name: string
  message: string
  stack?: string
}

type FailureCase = {
  error: ConnectivityError
  type: string
}

type ConnectivityTestResults = {
  failedTests: FailureCase[]
  success: boolean
}

async function testVonageConnectivity() {
  const res = await axios.get(
    `${import.meta.env.VITE_APP_API_URL}api/vonage/session`
  )
  console.log(res)
  console.log({
    apiKey: import.meta.env.VITE_VONAGE_PROJECT_API_KEY,
    sessionId: res.data.sessionId,
    token: res.data.hostToken,
  })

  const otNetworkTest = new NetworkTest(OT, {
    apiKey: import.meta.env.VITE_VONAGE_PROJECT_API_KEY,
    sessionId: res.data.sessionId,
    token: res.data.hostToken,
  })
  const connectivityResults = await otNetworkTest
    .testConnectivity()
    .then((results: ConnectivityTestResults) => {
      return results
    })
  return connectivityResults
}

/** Returns Mbps */
async function testDownloadSpeed() {
  const downloadSize = 17700000 // the number of bits of our test file
  const startTime = new Date().getTime()
  const cacheBuster = '?nnn=' + startTime

  const download = new Image()
  download.src =
    'https://d3mu6j6ibubo9g.cloudfront.net/tech-test.png' + cacheBuster
  // this returns when the image is finished downloading
  await download.decode()
  const endTime = new Date().getTime()
  const duration = (endTime - startTime) / 1000
  const bitsLoaded = downloadSize * 8
  const speedBps = bitsLoaded / duration
  const speedKbps = speedBps / 1024
  const speedMbps = speedKbps / 1024
  return speedMbps
}

interface TestResult {
  /** The network speed minimum b/w api.stream and Vonage. */
  networkSpeedMbps: number
  experienceLevel: 'Excellent' | 'Great' | 'Moderate' | 'Poor'
  experienceDesc: string
}

const TechTest = () => {
  const [isTesting, setIsTesting] = useState(false)
  const [testResult, setTestResult] = useState<TestResult | null>(null)

  async function onTestNetwork() {
    setIsTesting(true)
    const vonageResult = await testVonageConnectivity()
    const networkSpeedMbps = Math.round(await testDownloadSpeed())
    //
    // Given 1280x720p webcam settings per stream (via {@link useVonage})
    // See [Vonage Support](https://api.support.vonage.com/hc/en-us/articles/6646237236636-What-is-the-minimum-bandwidth-requirement-to-use-the-Vonage-Video-API-)
    //
    const experienceLevel =
      (0 <= networkSpeedMbps && networkSpeedMbps < 10) || !vonageResult.success
        ? 'Poor'
        : 10 <= networkSpeedMbps && networkSpeedMbps < 20
        ? 'Moderate'
        : 20 <= networkSpeedMbps && networkSpeedMbps < 30
        ? 'Great'
        : 'Excellent'
    const experienceDesc = !vonageResult.success
      ? 'You may be unable to stream your webcam during the tour. Please check your connection and try again.'
      : experienceLevel === 'Poor'
      ? 'Your connection may be too low to participate in a Tourable tour. Please move somewhere with better Internet and try again.'
      : experienceLevel === 'Moderate'
      ? 'You should be able to participate in the Tourable tour, but there may be moments that impact the quality of your tour. For the best experience, we recommend you move somewhere with a better connection'
      : experienceLevel === 'Great'
      ? 'All set! Your browser and connection are compatible with Tourable!'
      : 'All set! Your browser is ready for the best Tourable experience available.'

    setTestResult({ networkSpeedMbps, experienceLevel, experienceDesc })
    setIsTesting(false)
  }

  return (
    <div className='h-full'>
      <div className='flex h-full w-full flex-col items-center justify-center'>
        <div className='card w-96 bg-neutral text-neutral-content'>
          <div className='card-body'>
            <h2 className='card-title justify-center'>Compatibility check</h2>
            {testResult === null ? (
              <p className='text-center'>
                Ensure your virtual tour experience is seamless! Click to check
                browser compatibility and network speed before you begin.
              </p>
            ) : (
              <div>
                <div className='stats stats-vertical w-full shadow'>
                  <div className='stat place-items-center'>
                    <div className='stat-title'>Download Speed</div>
                    <div className='stat-value'>
                      {testResult.networkSpeedMbps} Mb
                    </div>
                    <div className='stat-desc'>From our 360° video servers</div>
                  </div>

                  <div className='stat place-items-center'>
                    <div className='stat-title'>Experience</div>
                    <div className='stat-value text-secondary'>
                      {testResult.experienceLevel}
                    </div>
                    <div className='stat-desc min-w-0 max-w-full whitespace-normal break-words text-center'>
                      {testResult.experienceDesc}
                    </div>
                  </div>
                  {/* <div className='stat place-items-center'> */}
                  {/*   <div className='stat-title'>Camera check</div> */}
                  {/*   <div className='stat-value text-secondary'> */}
                  {/*     {testResult.experienceLevel} */}
                  {/*   </div> */}
                  {/*   <div className='stat-desc min-w-0 max-w-full whitespace-normal break-words text-center'> */}
                  {/*     {testResult.experienceDesc} */}
                  {/*   </div> */}
                  {/* </div> */}
                </div>
              </div>
            )}
            <div className='card-actions justify-center pt-3'>
              <button
                disabled={isTesting}
                className='btn-primary btn'
                onClick={onTestNetwork}
              >
                <MdNetworkCheck className='h-6 w-6'></MdNetworkCheck>
                {isTesting
                  ? 'Testing'
                  : testResult === null
                  ? 'Run tests'
                  : 'Test again'}
              </button>
            </div>
            {isTesting && (
              <progress className='progress mx-auto w-56'></progress>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TechTest

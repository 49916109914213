import React, { useState } from 'react'
import useLocalStorage from 'use-local-storage'

const HeapIdentify = () => {
  const [identity, setIdentity] = useLocalStorage('heap-identity', null)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const handleSubmit = async event => {
    event?.preventDefault()
    if (name && email) {
      window?.heap?.identify(email)
      window?.heap?.addUserProperties({ name, email })
      setIdentity(email)
    }
  }

  return (
    <div className='flex h-[90vh] w-full flex-col items-center justify-center'>
      <form className='max-w-xs' onSubmit={handleSubmit}>
        <h1 className='mt-2 text-center text-3xl font-extrabold'>
          Welcome to Tourable
        </h1>
        <p className='mt-2 text-center text-base-content/80'>
          Get started with your college tour
        </p>
        <div className='form-control mt-8 w-full max-w-xs'>
          <label className='label'>
            <span className='label-text'>Full name</span>
          </label>
          <input
            type='text'
            className='input-bordered input w-full max-w-xs'
            value={name}
            onChange={event => setName(event?.target?.value)}
          />
        </div>
        <div className='form-control mt-1 w-full max-w-xs'>
          <label className='label'>
            <span className='label-text'>Email</span>
          </label>
          <input
            type='email'
            className='input-bordered input w-full max-w-xs'
            placeholder='name@school.edu'
            value={email}
            onChange={event => setEmail(event?.target?.value)}
          />
        </div>
        <p className='ml-1 mt-1.5 text-xs text-base-content/80'>
          Please enter your school or work email
        </p>
        <button className='btn-secondary btn-block btn mt-6'>
          Get started
        </button>
      </form>
    </div>
  )
}

export default HeapIdentify

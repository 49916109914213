import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'
import { nanoid } from 'nanoid'
import { setToken } from '../../store/actions/tokenActions'

const LoginScreen = props => {
  const { setToken } = props
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const login = async event => {
    setIsLoading(true)
    const https = axios.create({
      baseURL: `${import.meta.env.VITE_APP_API_URL}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
      },
    })
    let res = await https.get(`/api-stream-token/${nanoid(21)}`)
    setToken(res.data)
    navigate('/host')
    setIsLoading(false)
  }

  return (
    <div className='flex h-full items-center justify-center'>
      <div className='flex flex-col items-center'>
        <button
          className='btn-secondary btn'
          onClick={login}
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Create a tour room'}
        </button>
        {isLoading && (
          <div className='mt-4'>
            <p className='text-sm'>
              Creating a tour room can take up to 30 seconds!
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

const mapDispatchToProps = { setToken }
export default connect(null, mapDispatchToProps)(LoginScreen)

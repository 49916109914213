import React, { useState, useEffect } from 'react'
import { Helpers } from '@api.stream/studio-kit'
import ParticipantItem from '../../components/participant/ParticipantItem'

const { useStudio } = Helpers.React

const ParticipantManager = () => {
  const { room } = useStudio()
  const [participants, setParticipants] = useState([])

  useEffect(() => {
    return room.useParticipants(setParticipants)
  }, [room])

  return (
    <div className='min-h-[20vh] flex-1 overflow-auto rounded-[.5rem] bg-base-100 p-4'>
      <h2 className='text-2xl font-extrabold'>Participants</h2>
      <div className='mt-3'>
        {participants.map(participant => (
          <ParticipantItem key={participant.id} participant={participant} />
        ))}
      </div>
    </div>
  )
}

export default ParticipantManager

import axios from 'axios'
import * as OT from '@opentok/client'
import { create } from 'zustand'
import * as Sentry from '@sentry/react'
import toast from 'react-hot-toast'

/** In a Vonage call, the publisher with this name is the Host of the session. */
const HOST_VONAGE_NAME = 'HOST'
const GUEST_VONAGE_TAG = '[Guest]'

const PUBLIC_VONAGE_PROJECT_API_KEY = import.meta.env
  .VITE_VONAGE_PROJECT_API_KEY

type Participant = OT.Stream & { isHost: boolean }

interface VonageState {
  /** Determines whether Vonage has browser (WebRTC) compatibility  */
  isCompatible: boolean
  session?: OT.Session
  publisher?: OT.Publisher
  initialized: boolean
  /** Indicates the status of Vonage. `error` indicates an irrecoverable error has ocurred. */
  connectionStatus: 'uninitialized' | 'connecting' | 'connected' | 'error'
  isMicrophoneEnabled: boolean
  isCameraEnabled: boolean
  layout: 'three' | 'hostOnly' | 'hidden'
  /** A list of all other participants, not including the publisher. */
  participants: Participant[]
  cameraAccessAllowed: boolean
  toggleCamera: () => void
  toggleMicrophone: () => void
  toggleLayout: () => void
  initialize: (params: InitVonageParams) => Promise<void>
}

const useVonageStore = create<VonageState>()((set, get) => ({
  isCompatible: OT.checkSystemRequirements() == 1,
  initialized: false,
  connectionStatus: 'uninitialized',
  session: undefined,
  publisher: undefined,
  isMicrophoneEnabled: true,
  isCameraEnabled: true,
  layout: 'three',
  participants: [],
  cameraAccessAllowed: true,
  initialize: async params => {
    if (get().initialized)
      throw new Error('Cannot initialize Vonage more than once!')
    set({ connectionStatus: 'connecting' })
    const vonage = await initVonage(params)
    set({
      initialized: true,
      // There's an implicit spread operator. These properties merge with the current state.
      connectionStatus: 'connected',
      session: vonage.session,
      publisher: vonage.publisher,
    })
  },
  toggleCamera: () => {
    if (!get().initialized) throw new Error('Vonage must first be initialized.')
    get().publisher.publishVideo(!get().isCameraEnabled)
    set({ isCameraEnabled: !get().isCameraEnabled })
  },
  toggleMicrophone: () => {
    if (!get().initialized) throw new Error('Vonage must first be initialized.')
    get().publisher.publishAudio(!get().isMicrophoneEnabled)
    set({ isMicrophoneEnabled: !get().isMicrophoneEnabled })
  },
  toggleLayout: () => {
    set({
      layout:
        get().layout === 'three'
          ? 'hostOnly'
          : get().layout === 'hostOnly'
          ? 'hidden'
          : 'three',
    })
  },
}))
export default useVonageStore

interface InitVonageParams {
  userIsHost: boolean
  sessionId: string
  /** Called when Vonage encounters an exception with a friendly error message. */
  onFatalError?: (msg: string) => void
  divHost: HTMLElement
  divSelf: HTMLElement
  divOther: HTMLElement
  divOthersHostView: HTMLElement
  displayName: string
}

async function initVonage(
  params: InitVonageParams
): Promise<{ session: OT.Session; publisher: OT.Publisher }> {
  // generate a guest token on the backend with your correct permissions for the session
  const response = await axios.get(
    `${import.meta.env.VITE_APP_API_URL}api/vonage/session/${params.sessionId}`
  )
  const token = response.data.guestToken

  const session = OT.initSession(
    PUBLIC_VONAGE_PROJECT_API_KEY,
    params.sessionId
  )

  // register global OT error handler
  OT.on('exception', e => handleAllOTExceptions(e, session, token, params))

  // register callbacks on the session object that update the UI
  registerSessionUICallbacks(session, token, params)

  console.log('connecting to session')
  // connect to session
  await connectSessionAsync(session, token)

  const publisher = await publishAndRegisterUICallbacks(session, params, token)

  return { session, publisher }
}

/** Begin publishing your webcam and listen for errors to update the UI. */
async function publishAndRegisterUICallbacks(
  session: OT.Session,
  initParams: InitVonageParams,
  token: string
) {
  // publish webcam
  console.log('attempting initPublisher')
  const publisher = await initPublisher(initParams)
  console.log('initPublisher success')

  console.log('registering publisher UI callbacks')
  // register callbacks on the publisher object
  registerPublisherUICallbacks(session, publisher, token, initParams)

  console.log('publishing webcam to session')
  await publishWebcamToSession(session, publisher)
  return publisher
}

/** Connect to a Vonage session. */
async function connectSessionAsync(session: OT.Session, token: string) {
  await new Promise<void>((resolve, reject) => {
    session.connect(token, error => {
      if (error) {
        reject(
          new Error(
            `Error connecting to Vonage session: ${error.name}, ${error.message}`
          )
        )
      } else {
        resolve()
      }
    })
  })
}

/** Refresh all subscriptions. Rerender all Vonage divs.  */
async function subscribeToAllParticipants(
  participants: Participant[],
  session: OT.Session,
  initParams: InitVonageParams
) {
  console.log(
    `refreshStreamSubscriptions called with ${participants.length} participants:`,
    participants
  )
  const host = participants.find(p => p.isHost)
  // get the first non-host participant in alphabetical order
  const firstNonHost = participants
    .sort((a, b) => {
      const nameA = a.name.toLowerCase() // Convert to lowercase for case-insensitive sorting
      const nameB = b.name.toLowerCase()
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0 // Names are equal
    })
    .find(p => !p.isHost)

  if (host !== undefined)
    await subscribeToParticipant(session, host, initParams.divHost)
  if (firstNonHost !== undefined)
    await subscribeToParticipant(session, firstNonHost, initParams.divOther)
}

const guestViewSubscribeOpts: OT.SubscriberProperties = {
  style: {
    // disable visual audio indicator
    audioLevelDisplayMode: 'off',
    // enable mute button
    buttonDisplayMode: 'off',
    nameDisplayMode: 'off',
  },
  // fill div if this resolution is unavailable to the browser
  fitMode: 'cover',
  height: '100%',
  width: '100%',
  insertMode: 'replace',
}

const hostViewSubscribeOpts: OT.SubscriberProperties = {
  ...guestViewSubscribeOpts,
  insertMode: 'append',
}

/** Subscribe to participant `p` in element `d`. */
async function subscribeToParticipant(
  session: OT.Session,
  p: Participant,
  d: HTMLElement,
  options = guestViewSubscribeOpts
) {
  console.log(
    `SubscribeToParticipant called for ${p.name} with id ${p.streamId} and rendering their stream in parent element:`,
    d.parentElement,
    'the div is',
    d
  )
  const subscriber = await new Promise<OT.Subscriber>((resolve, reject) => {
    const s = session.subscribe(p, d, options, error => {
      if (error) {
        reject(
          new Error(
            `Failed to subscribe to participant ${p.name} with id ${p.streamId}. ${error.name}: ${error.message}.`
          )
        )
      } else {
        console.log(
          `Successfully Subscribed to participant ${p.name} with id ${p.streamId} and rendering their stream in parent element:`,
          d.parentElement
        )
        resolve(s)
      }
    })
  })
  // maybe we maintain a separate list of subscribers? or idk
  subscriber.on('connected', () => {
    console.log('subscriber onConnected called')
  })
  // https://tokbox.com/developer/sdks/js/reference/Subscriber.html
  subscriber.on('destroyed', event => {
    if (event) {
      event.preventDefault()
      console.log('Subscriber destroyed.', event)
    }
  })
}

/** Render the user's webcam feed on the frontend. */
async function initPublisher(initParams: InitVonageParams) {
  return await new Promise<OT.Publisher>((resolve, reject) => {
    // publish the current user's camera
    const publisher = OT.initPublisher(
      initParams.divSelf,
      // the participant has an empty name if they are not the host
      {
        // the possible aspect ratios are 16:9 or 4:3
        resolution: initParams.userIsHost ? '1280x720' : '640x360',
        scalableVideo: true,
        name: initParams.userIsHost
          ? HOST_VONAGE_NAME
          : `${GUEST_VONAGE_TAG} ${initParams.displayName}`,
        // fill div if this resolution is unavailable to the browser
        fitMode: 'cover',
        height: '100%',
        width: '100%',
        style: {
          // disable visual audio indicator
          audioLevelDisplayMode: 'off',
          // disable mic button
          buttonDisplayMode: 'off',
          nameDisplayMode: 'off',
        },
      },
      error => {
        if (error) {
          reject(
            new Error(
              `Error initializing webcam as a publisher to Vonage: ${error.name}, ${error.message}`
            )
          )
        } else {
          resolve(publisher)
        }
      }
    )
  })
}

/** Publish a local feed to the rest of the session so that others may subscribe to it. */
async function publishWebcamToSession(
  session: OT.Session,
  publisher: OT.Publisher
) {
  return await new Promise<void>((resolve, reject) => {
    session.publish(publisher, error => {
      if (error) {
        if (error.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
          useVonageStore.setState({
            cameraAccessAllowed: false,
          })
        }
        reject(
          new Error(
            `Error publishing webcam to Vonage: ${error.name}, ${error.message}`
          )
        )
      } else {
        resolve()
      }
    })
  })
}

async function sleep(ms: number) {
  await new Promise(resolve => setTimeout(resolve, ms))
}

/** Register handlers that update state according to some session event. */
function registerSessionUICallbacks(
  session: OT.Session,
  token: string,
  initParams: InitVonageParams
) {
  session.on('sessionDisconnected', event => {
    event.preventDefault()
    console.log(`Vonage session disconnected due to ${event.reason}`, event)
    if (event.reason === 'networkDisconnected') {
      console.log('Detected network disconnect')
      useVonageStore.setState({ connectionStatus: 'connecting' })
      reconnectLoop(session, token, initParams)
        .then(() => useVonageStore.setState({ connectionStatus: 'connected' }))
        .catch(e => {
          useVonageStore.setState({ connectionStatus: 'error' })
          console.log('Failed to reconnect to Vonage:', e)
        })
    }
  })
  session.on('sessionReconnecting', () => {
    console.log('Vonage is attempting a reconnect...')
  })
  session.on('sessionReconnected', () => {
    console.log('Vonage session reconnected!')
  })
  session.on('sessionConnected', () => {
    console.log('Vonage session connected')
  })
  session.on('streamCreated', event => {
    // someone else joins the session
    const stream = event.stream
    console.log(
      'New stream in the session: ' + stream.streamId,
      'with name',
      event.stream.name
    )
    const newParticipant: Participant = {
      ...event.stream,
      ...{ isHost: event.stream.name === HOST_VONAGE_NAME },
    }
    useVonageStore.setState(state => ({
      participants: [...state.participants, newParticipant],
    }))
    if (initParams.userIsHost) {
      // See https://tokbox.com/developer/sdks/js/reference/Session.html#subscribe
      subscribeToParticipant(
        session,
        newParticipant,
        initParams.divOthersHostView,
        hostViewSubscribeOpts
      ).catch(e => console.log('Failed to subscribe to participant', e))
    } else {
      const allParticipants = useVonageStore.getState().participants
      subscribeToAllParticipants(allParticipants, session, initParams).catch(
        e => console.log('Failed to subscribe to all participants', e)
      )
    }
  })
  session.on('streamDestroyed', event => {
    // someone else leaves the session
    // prevent div from being destroyed
    event.preventDefault()
    const stream = event.stream
    console.log(
      'Stream destroyed: ' + stream.streamId,
      ' with name ',
      event.stream.name
    )
    useVonageStore.setState(state => ({
      participants: state.participants.filter(
        participant => participant.name !== event.stream.name
      ),
    }))
    const allParticipants = useVonageStore.getState().participants
    subscribeToAllParticipants(allParticipants, session, initParams).catch(e =>
      console.error('Failed to subscribe to all participants', e)
    )
  })
}

/** Register handlers that update state according to some publisher event. */
function registerPublisherUICallbacks(
  session: OT.Session,
  publisher: OT.Publisher,
  token: string,
  initParams: InitVonageParams
) {
  publisher.on('streamCreated', event => {
    console.log('Vonage started streaming your webcam.', event)
  })
  publisher.on('streamDestroyed', event => {
    // Via https://tokbox.com/developer/sdks/js/reference/OT.html#initPublisher
    // if you intend to reuse a publisher object, prevent the Publisher's video from being removed from the page.
    event.preventDefault()
    console.log('Vonage stopped streaming your webcam.', event)
    // if publisher disallows camera access in the middle of a stream
    if (event.reason === 'mediaStopped')
      useVonageStore.setState({
        cameraAccessAllowed: false,
      })
    if (event.reason === 'clientDisconnected') {
      reconnectLoop(session, token, initParams)
        .then(() => useVonageStore.setState({ connectionStatus: 'connected' }))
        .catch(e => {
          useVonageStore.setState({ connectionStatus: 'error' })
          console.log('Failed to reconnect to Vonage:', e)
        })
    }
  })
}

/**
 * Interal vonage error codes. Errors with these codes are thrown when OpenTok encounters an exception internally. Can be handled with `OT.on()`.
 * See [API reference](https://tokbox.com/developer/sdks/js/reference/ExceptionEvent.html) for the full list.
 */
enum OTErrCodes {
  ConnectFailed = 1006,
  ConnectRejected = 1007,
  ConnectTimeout = 1008,
  UnableToPublish = 1500,
  /**
   * Seems to be thrown if I open up a host tab and > 4 guest tabs within firefox.
   * OT.Subscriber PeerConnection Error: The stream was unable to connect due to a network error. Make sure your connection isn't blocked by a firewall.
   */
  SubscriberICEWorkflowFailed = 1554,
  /**
   * Occurs when I am the host in one tab and the participant in another,
   * and I disconnect from the network, returning online to the guest tab.
   */
  UnableToSubscribe = 1501,
}

type ErrorMessage = string

type OTErrorHandler =
  | { isFatalToTourable: false; handle: OTErrorHandlerFn }
  | { isFatalToTourable: true; fatalUiMsg: string }
type OTErrorHandlerFn = (error: OT.ExceptionEvent) => void

/**
 * Handle any number of internal OT/Vonage errors, along with a default behavior for unhandled errors.
 *
 * The key associated with each error handler is the `OT.ExceptionEvent.code` if defined else the key is `OT.ExceptionEvent.message`
 *
 * Prefer the code if available.
 */
interface OTErrorHandlers {
  /** The key is an `OTErrCode`. */
  [key: number]: OTErrorHandler
  /** If the error code is undefined, the key is the `error.message` */
  [key: ErrorMessage]: OTErrorHandler
}

/** A global error handler that updates state according to some exception event. */
function handleAllOTExceptions(
  error: OT.ExceptionEvent,
  session: OT.Session,
  token: string,
  initParams: InitVonageParams
) {
  console.log('Handling a global Vonage exception.')
  const reconnectHandler: OTErrorHandlerFn = e => {
    console.log()
    const connectionState = useVonageStore.getState().connectionStatus
    // skip the reconnect if already connecting or loop timed out
    if (connectionState !== 'connecting' && connectionState !== 'error') {
      console.log('Handling Vonage error with a reconnect attempt', e)
      useVonageStore.setState({ connectionStatus: 'connecting' })
      // eslint-disable-next-line promise/no-promise-in-callback
      reconnectLoop(session, token, initParams)
        .then(() => {
          useVonageStore.setState({ connectionStatus: 'connected' })
          console.log('Vonage reconnect loop succeeded.')
          return
        })
        .catch(e => {
          useVonageStore.setState({ connectionStatus: 'error' })
          console.log('Vonage reconnect loop failed.', e)
        })
    }
  }
  const ignoreHandler: OTErrorHandlerFn = e => {
    console.log('Determined it was safe to ignore Vonage error', e)
  }

  const otErrorHandlers: OTErrorHandlers = {
    [OTErrCodes.ConnectFailed]: {
      isFatalToTourable: false,
      handle: reconnectHandler,
    },
    [OTErrCodes.ConnectRejected]: {
      isFatalToTourable: false,
      handle: reconnectHandler,
    },
    [OTErrCodes.ConnectTimeout]: {
      isFatalToTourable: false,
      handle: reconnectHandler,
    },
    [OTErrCodes.UnableToPublish]: {
      isFatalToTourable: true,
      fatalUiMsg:
        'Oops, something went wrong when trying to stream your webcam. Please refresh and try again.',
    },
    [OTErrCodes.SubscriberICEWorkflowFailed]: {
      isFatalToTourable: true,
      fatalUiMsg:
        'Oops, something went wrong when trying to stream your webcam. Please refresh and try again.',
    },
    [OTErrCodes.UnableToSubscribe]: {
      isFatalToTourable: false,
      handle: () => {
        // Suppress the error but make note of it. This should not happen.
        console.log(
          'Unable to subscribe to a stream. It is possible our local state is not in sync with Vonage. Maybe clear state?',
          session
        )
      },
    },
    /**
     * Occurs in `session.subscribe` if two publishers stop streaming at the same time.
     * We're trying to subscribe to a stream that's already been destroyed.
     */
    'Stream was destroyed before it could be subscribed to': {
      isFatalToTourable: false,
      // The session continues to work as expected.
      handle: ignoreHandler,
    },
  }

  const handler = otErrorHandlers[error.code] ?? otErrorHandlers[error.message]
  if (handler !== undefined) {
    console.log('Handled error ', error)
    // if fatal, notify UI
    if (handler.isFatalToTourable && initParams.onFatalError !== undefined) {
      initParams.onFatalError(handler.fatalUiMsg)
    }

    // handle error
    if (handler.isFatalToTourable === false) {
      handler.handle(error)
    }
  } else {
    // error is unhandled
    console.log('There was an unhandled error!', error)
  }

  // log everything to Sentry
  // Although Sentry normally logs exceptions, these are caught and passed to this callback.
  Sentry.captureException(new Error(`Vonage: ${error.message}`), {
    extra: { ...error },
  })
}
async function reconnectLoop(
  session: OT.Session,
  token: string,
  initParams: InitVonageParams
) {
  if (session.connection) {
    console.log('Ended the session before reconnecting...')
    session.disconnect()
  }
  // attempt reconnect
  const reconnectLoop = async () => {
    // clear the participants list so that, upon reconnecting to the session, local state in sync with server state
    useVonageStore.setState({ participants: [] })
    let connected = false
    while (connected === false) {
      // attempt reconnect
      const reconnect = async () => {
        await connectSessionAsync(session, token)
        // session is reconnected!
        // give it a couple seconds to get settled
        await sleep(2000)
        // now let's try to publish again
        const publisher = await publishAndRegisterUICallbacks(
          session,
          initParams,
          token
        )
        // update state
        useVonageStore.setState({ publisher })
      }
      await reconnect()
        .then(() => (connected = true))
        .catch(async () => {
          console.log('Failed manual reconnect. Sleeping and trying again.')
          // sleep for 3sec
          await sleep(3000)
        })
    }
    console.log('Manual reconnect suceeded!')
  }
  const reconPromise = reconnectLoop()
  toast.promise(reconPromise, {
    loading: 'Reconnecting...',
    success: "You're back online!",
    error:
      'Oops, there was a problem connecting to Tourable. Please refresh and try again.',
  })
  await reconPromise
}

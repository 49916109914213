import { useState, useEffect } from 'react'
import { init, Helpers } from '@api.stream/studio-kit'
import ProjectView from './ProjectView'
import { JoinForm } from './JoinForm'
import { config } from '../../config/config'
import { useSearchParams } from 'react-router-dom'

const { useStudio } = Helpers.React
const { Room } = Helpers

const DEFAULT_GUEST_NAME = 'Guest-' + Math.floor(Math.random() * 1e4)

const GuestView = () => {
  const {
    studio,
    project,
    room,
    setStudio,
    setProject,
    setRoom,
    projectCommands,
  } = useStudio()
  const [joining, setJoining] = useState(false)
  const [displayName, setDisplayName] = useState(DEFAULT_GUEST_NAME)
  const [error, setError] = useState()
  const [inRoom, setInRoom] = useState(false)
  const [selfId, setSelfId] = useState()
  const [showMap, setShowMap] = useState(false)
  const [mapPos, setMapPos] = useState({})
  const [ensureDevices, setEnsureDevices] = useState<
    'pending' | 'allowed' | 'blocked'
  >('pending')

  // get api.stream and vonage "room" ID's
  const [searchParams, setSearchParams] = useSearchParams()
  const vonageSessionId = searchParams.get('vonageSessionId')
  const apiStreamGuestToken = searchParams.get('at')

  window.SDK = useStudio()

  useEffect(() => {
    if (ensureDevices === 'allowed') return
    Room.ensureDevicePermissions().then(permissions =>
      setEnsureDevices(
        permissions.audio && permissions.video ? 'allowed' : 'blocked'
      )
    )
  }, [ensureDevices, setEnsureDevices])

  // Initialize studio
  useEffect(() => {
    if (!joining || studio) return
    init({
      guestToken: apiStreamGuestToken,
      env: config.env,
    })
      .then(setStudio)
      .catch(e => {
        console.warn(e)
        setError(e.message)
      })
  }, [joining, studio, setStudio, apiStreamGuestToken])

  // Initialize project
  useEffect(() => {
    if (!studio) return

    if (studio.initialProject) {
      // If the SDK detects was initialized with a token in init() or detects a token in the URL,
      // it will return the project associated with it (e.g. guest view)
      setProject(studio.initialProject)
    } else {
      setError('Invalid token')
    }
  }, [studio, setProject])

  // Initialize room
  useEffect(() => {
    if (!project || inRoom) return
    setInRoom(true)
    project
      .joinRoom({
        displayName,
      })
      .then(room => {
        setJoining(false)
        setRoom(room)
        room.sendData({
          type: 'RequestMapState',
        })
      })
      .catch(e => {
        setError(e.message)
        setInRoom(false)
      })
  }, [project, displayName, inRoom, setRoom])

  // Send UserJoinRequest after timeout
  useEffect(() => {
    if (!room) return
    room.sendData({ type: 'UserJoinRequest' })
  }, [room])

  useEffect(() => {
    if (!room || !projectCommands) return

    // set self id
    const participants = room.getParticipants()
    participants?.forEach(participant => {
      if (participant.isSelf) {
        setSelfId(participant.id)
      }
    })

    // on unmount, prune to remove self from participant list
    return room.useParticipants(() => {
      projectCommands.pruneParticipants()
    })
  }, [room, projectCommands])

  useEffect(() => {
    if (!room) return
    room.onData((data, senderId) => {
      if (
        data.type === 'MapStateChange' &&
        room.getParticipant(senderId).role === 'ROLE_HOST'
      ) {
        setShowMap(data.visible)
        setMapPos(data.pos)
      } else if (
        data.type === 'PosChanged' &&
        room.getParticipant(senderId).role === 'ROLE_HOST' &&
        project
      ) {
        project.props.panorama.setPosition(data.pos)
      }
    })
  }, [room, project, setShowMap, setMapPos])

  if (error) {
    return (
      <div className='flex h-[80vh] w-full items-center justify-center'>
        <div className='flex flex-col items-center'>
          <p className='mt-6 font-medium'>{error}</p>
        </div>
      </div>
    )
  }

  if (joining) {
    return (
      <div className='flex h-[80vh] w-full items-center justify-center'>
        <div className='flex flex-col items-center'>
          <span className='loading loading-spinner loading-lg'></span>
          <p className='mt-6 font-medium'>Joining as {displayName}</p>
        </div>
      </div>
    )
  }

  if (!room) {
    return (
      <JoinForm
        setJoining={setJoining}
        setDisplayName={setDisplayName}
        displayName={displayName}
        ensureDevices={ensureDevices}
      />
    )
  }

  return (
    <ProjectView
      selfId={selfId}
      showMap={showMap}
      mapPos={mapPos}
      vonageSessionId={vonageSessionId}
      displayName={displayName}
    />
  )
}

export default GuestView

import { useLocation } from 'react-router-dom'
import HostHeader from '../pages/host/HostHeader'
import GuestHeader from '../pages/guest/GuestHeader'

const AppLayout = props => {
  const { pathname } = useLocation()

  return (
    <div className='flex h-screen flex-col bg-base-300'>
      <div>
        {pathname.startsWith('/guest') ? <GuestHeader /> : <HostHeader />}
      </div>
      <div className='flex-grow-1 h-full'>{props.children}</div>
    </div>
  )
}

export default AppLayout

import { useEffect, useState } from 'react'
import { Helpers } from '@api.stream/studio-kit'
import { AiOutlineCaretRight, AiOutlineCaretDown } from 'react-icons/ai'
import { ListObjectsV2Command, S3Client } from '@aws-sdk/client-s3'
const { useStudio } = Helpers.React

const s3Prefix = 'universities/'

async function parseVideosFromResponse(response) {
  const videosByCollege = {}

  response.Contents?.filter(item => item.Key.endsWith('media.m3u8')).forEach(
    item => {
      const [college, title, _] = item.Key.replace(s3Prefix, '').split('/')

      if (!Object.keys(videosByCollege)?.includes(college)) {
        videosByCollege[college] = []
      }
      videosByCollege[college]?.push({
        college,
        title,
        key: item?.Key,
        url: `https://d3mu6j6ibubo9g.cloudfront.net/universities/${college}/${encodeURIComponent(
          title
        )}/media.m3u8`,
      })
    }
  )
  return videosByCollege
}

const getVideosByCollege = async () => {
  const client = new S3Client({
    region: 'us-east-1',
    credentials: {
      accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY_ID,
      secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY,
    },
  })

  let videosByCollege = {}
  // AWS can return a maximum of 1000 keys per request
  // if the response is truncated, keep requesting
  let response
  let continueRequesting = true
  while (continueRequesting) {
    const cmd = new ListObjectsV2Command({
      Bucket: 'nextfourconvert',
      Prefix: s3Prefix,
      // conditionally add ContinuationToken if the previous response was
      // truncated
      ...(response?.IsTruncated
        ? { ContinuationToken: response.NextContinuationToken }
        : {}),
    })
    response = await client.send(cmd)
    // merge new videos with old videos
    videosByCollege = {
      ...videosByCollege,
      ...(await parseVideosFromResponse(response)),
    }
    continueRequesting = response.IsTruncated
  }
  return videosByCollege
}

const VideoTree = props => {
  const { mapStatus, showMap, setShowMap, collapsed, toggleCollapsed } = props
  const { studio, room, projectCommands } = useStudio()
  const [expandedCollege, setExpandedCollege] = useState(-1)
  const [currentVideo, setCurrentVideo] = useState('')
  const [time, setTime] = useState(0)
  const [videosByCollege, setVideosByCollege] = useState({})

  useEffect(() => {
    getVideosByCollege().then(setVideosByCollege)
  }, [])

  useEffect(() => {
    return studio.subscribe((event, payload) => {
      if (event === 'VideoTimeUpdate') {
        setTime(payload.time)
      }
    })
  }, [studio])

  const toggleMap = () => {
    setShowMap(!showMap)
    room.sendData({
      type: 'MapStateChange',
      visible: !showMap,
      pos: { lat: 43, lng: 43 },
    })
  }

  const playVideo = video => {
    if (currentVideo !== video.title) {
      setCurrentVideo(video.title)
      projectCommands.addVideoOverlay(video.title, {
        src: video.url,
      })
    } else {
      setCurrentVideo('')
      projectCommands.removeVideoOverlay(video.title)
    }
  }

  return (
    <div className='relative flex max-h-[80vh] w-[650px] flex-col justify-between overflow-auto rounded rounded-tr-none bg-base-100 p-4 drop-shadow-md'>
      <div>
        <h1 className='text-[1.5rem] font-extrabold'>College videos</h1>
        <ul className='mt-3'>
          {Object.entries(videosByCollege).map(([collegeName, videos]) => (
            <div key={`category-${collegeName}`} className='my-1 flex flex-col'>
              {/* karat: expand / collapse */}
              <div
                className='flex cursor-pointer items-center gap-2'
                onClick={() => {
                  if (expandedCollege === collegeName) {
                    setExpandedCollege('')
                  } else {
                    setExpandedCollege(collegeName)
                  }
                }}
              >
                {expandedCollege === collegeName ? (
                  <AiOutlineCaretDown className='text-base-content/60' />
                ) : (
                  <AiOutlineCaretRight className='text-base-content/60' />
                )}
                <span className='text-[1.25rem] font-semibold'>
                  {collegeName}
                </span>
              </div>

              {/* expanded video list */}
              {expandedCollege === collegeName &&
                videos &&
                videos.map(video => (
                  <div
                    key={`video-${collegeName}-${video.title}`}
                    className='my-0.5'
                  >
                    <button
                      className={`text-[1.25rem] ${
                        currentVideo === video.title ? 'underline' : ''
                      } ml-4 cursor-pointer text-sm text-base-content/80`}
                      onClick={() => playVideo(video)}
                    >
                      {video.title?.includes('m3u8')
                        ? video.title?.split('.m3u8')[0]
                        : video.title}
                    </button>
                  </div>
                ))}
            </div>
          ))}
        </ul>
      </div>
      <button
        type='button'
        className='btn-secondary btn-block btn mt-6'
        onClick={toggleMap}
        disabled={!mapStatus}
      >
        Launch Google Maps
      </button>
    </div>
  )
}

export default VideoTree

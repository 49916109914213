import React from 'react'
import { MdOutlineArrowBackIosNew } from 'react-icons/md'

const GuestHeader = () => {
  return (
    <div className='absolute left-[2.25rem] top-[2.25rem] z-20'>
      <a
        href='https://www.tourable.net/profile/tours'
        target='_blank'
        rel='noreferrer'
      >
        <button className='btn-sm btn border-transparent bg-[#F3F3F3] text-[#1e293b] shadow-[0_4px_4px_rgba(0,0,0,.25)] hover:border-transparent hover:bg-[#F3F3F3]'>
          <MdOutlineArrowBackIosNew className='text-[#1e293b]' /> My tours
        </button>
      </a>
    </div>
  )
}

export default GuestHeader

export const connectionQualityToClass = {
  excellent: 'badge-success',
  good: 'badge-success',
  poor: 'badge-warning',
  unknown: 'badge-error',
}

export const connectionQualityToMessage = {
  excellent: '',
  good: '',
  poor: 'Poor connection',
  unknown: 'Not connected',
}

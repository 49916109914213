import React from 'react'
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from 'react-icons/md'

const CollapseButton = props => {
  const { collapsed, onClick } = props

  return (
    <button
      type='button'
      className='btn-primary rounded-r-[.25rem] px-2 py-4'
      onClick={onClick}
    >
      {collapsed ? (
        <MdOutlineArrowForwardIos className='text-inherit' />
      ) : (
        <MdOutlineArrowBackIosNew className='text-inherit' />
      )}
    </button>
  )
}

export default CollapseButton

import axios from 'axios'
import { useState, useEffect, useRef } from 'react'
import { Helpers } from '@api.stream/studio-kit'
import { Loader } from '@googlemaps/js-api-loader'
import ChatView from '../../components/chat/ChatView'
import ParticipantManager from './ParticipantManager'
import VideoTree from './VideoTree'
import HostMap from './HostMap'
import CollapseButton from '../../components/sidebar/CollapseButton'
import Vonage from '../../components/Vonage'

const { useStudio } = Helpers.React
const { Room } = Helpers

const getUrl = () => window.location.protocol + '//' + window.location.host

async function createVonageSession() {
  const res = await axios.get(
    `${import.meta.env.VITE_APP_API_URL}api/vonage/session`
  )
  return res.data.sessionId
}

const loader = new Loader({
  apiKey: import.meta.env.VITE_APP_MAP_KEY,
  version: 'weekly',
})

const ProjectView = ({ displayName }) => {
  const { studio, project, room, projectCommands } = useStudio()
  const renderContainer = useRef()
  const [guestUrl, setGuestUrl] = useState('')
  const [selfId, setSelfId] = useState()
  const [chatHistory, setChatHistory] = useState([])
  const [showMap, setShowMap] = useState(false)
  const [mapStatus, setMapStatus] = useState(false)
  const [lastPos, setLastPos] = useState({ lat: 42.454323, lng: -76.475266 })
  const [collapsed, setCollapsed] = useState(false)

  const [sessionId, setSessionId] = useState('')
  const [draft, setDraft] = useState('')

  useEffect(() => {
    Room.ensureDevicePermissions()
  })

  useEffect(() => {
    loader.load().then(() => {
      setMapStatus(true)
    })
  }, [])

  useEffect(() => {
    async function createGuestURL() {
      // wait for api.stream studio to be initialized
      if (studio) {
        const vonageSessionId = await createVonageSession()
        const guestUrl = `${getUrl()}/guest?vonageSessionId=${vonageSessionId}`
        setSessionId(vonageSessionId)

        // createGuestLink method adds an additional query parameter onto the URL
        // called "at", which contains the api.stream guest token
        studio.createGuestLink(guestUrl).then(setGuestUrl)
      }
    }
    createGuestURL()
  }, [studio])

  useEffect(() => {
    if (!studio || !project.id) return
    studio.render({
      containerEl: renderContainer.current,
      projectId: project.id,
      dragAndDrop: false,
    })
  }, [studio, renderContainer, project])

  useEffect(() => {
    if (!room || !projectCommands) return

    const participants = room.getParticipants()
    participants?.forEach(participant => {
      if (participant.isSelf) {
        setSelfId(participant.id)
      }
    })

    return room.useParticipants(() => {
      projectCommands.pruneParticipants()
    })
  }, [room, projectCommands])

  useEffect(() => {
    if (room) {
      room.useChatHistory(history => {
        setChatHistory(history)
      })
    }
  }, [room])

  useEffect(() => {
    if (!room) return
    room.onData(data => {
      if (data.type === 'RequestMapState') {
        room.sendData({
          type: 'MapStateChange',
          visible: showMap,
          pos: lastPos,
        })
      }
    })
  }, [room, project, showMap, lastPos])

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  if (!room) return null

  return (
    <div className=''>
      {/* guest url */}
      <div className='mx-4 my-2 flex items-center gap-3 rounded-xl bg-base-100 px-4 py-2'>
        <label className='flex-shrink-0 font-semibold'>Guest URL</label>
        <input
          className='min-w-[300px] flex-1 rounded-xl bg-base-content/20 p-2'
          type='text'
          value={guestUrl}
          readOnly={true}
        />
      </div>

      <div className='relative flex flex-col justify-center pb-4 lg:flex-row'>
        {/* video tree */}
        <div
          className={`${
            collapsed ? 'hidden' : 'absolute'
          } bottom-4 left-0 top-0 z-10 m-2 inline-flex min-w-[22rem] max-w-[22rem] flex-1`}
        >
          <VideoTree
            mapStatus={mapStatus}
            showMap={showMap}
            setShowMap={setShowMap}
            collapsed={collapsed}
            toggleCollapsed={toggleCollapsed}
          />
          <div>
            <CollapseButton collapsed={collapsed} onClick={toggleCollapsed} />
          </div>
        </div>
        {collapsed && (
          <div className='absolute left-0 top-5 z-10'>
            <CollapseButton collapsed={collapsed} onClick={toggleCollapsed} />
          </div>
        )}

        {/* dashboard */}
        <div className='relative m-2 flex flex-col gap-4 lg:w-[calc(100%-28rem)] lg:max-w-[50rem] lg:flex-1'>
          <div
            ref={renderContainer}
            className='aspect-video w-full overflow-hidden rounded-[.5rem]'
          />
          {showMap && (
            <div className='absolute aspect-video w-full overflow-hidden rounded-[.5rem]'>
              <HostMap room={room} lastPos={lastPos} setLastPos={setLastPos} />
            </div>
          )}
          <div className='hidden flex-1 lg:flex lg:max-h-[16.8rem]'>
            <ParticipantManager showMap={showMap} lastPos={lastPos} />
          </div>
        </div>
        <div className='m-2 flex flex-col gap-4 lg:min-w-[25rem]'>
          {sessionId && (
            <Vonage
              isHost={true}
              sessionId={sessionId}
              displayName={displayName}
            />
          )}
          <div className='flex flex-1 flex-col gap-4 md:flex-row'>
            <div className='flex max-h-[23rem] md:flex-1 lg:hidden'>
              <ParticipantManager />
            </div>
            <div className='flex max-h-[23rem] w-full flex-row lg:max-h-[calc(100vh-32rem)] lg:min-h-[calc(100vh-32rem)] 2xl:max-h-[calc(100vh-27rem)] 2xl:min-h-[calc(100vh-27rem)]'>
              <ChatView
                chatHistory={chatHistory}
                selfId={selfId}
                draft={draft}
                setDraft={setDraft}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectView

import React from 'react'
import {
  connectionQualityToClass,
  connectionQualityToMessage,
} from '../../utils/connectionQuality'

const ParticipantItem = ({ participant }) => {
  return (
    <div
      className='my-1 flex items-center rounded-xl bg-base-200 px-4 py-2'
      key={participant.id}
    >
      <span
        className={`badge badge-secondary badge-xs ${
          connectionQualityToClass[participant?.connectionQuality]
        }`}
      ></span>
      <p className='ml-2 font-medium'>{participant.displayName}</p>
      <p className='ml-3 text-sm font-medium text-base-content/80'>
        {connectionQualityToMessage[participant?.connectionQuality]}
      </p>
    </div>
  )
}

export default ParticipantItem

import React, { useEffect } from 'react'

const google = (window.google = window.google ? window.google : {})

const HostMap = props => {
  // IGNORE React Hook useEffect has missing dependencies
  const { room, lastPos, setLastPos } = props

  useEffect(() => {
    const holder = document.getElementById('pano')
    const mapHolder = document.getElementById('map')
    if (!holder || !mapHolder || !room) return

    let map = new google.maps.Map(mapHolder, {
      center: {
        lat: lastPos.lat,
        lng: lastPos.lng,
      },
      zoom: 14,
    })

    let panorama = new google.maps.StreetViewPanorama(holder, {
      position: {
        lat: lastPos.lat,
        lng: lastPos.lng,
      },
      pov: {
        heading: 34,
        pitch: 10,
      },
    })

    map.setStreetView(panorama)

    panorama.addListener('position_changed', () => {
      setLastPos({
        lat: panorama.getPosition().lat(),
        lng: panorama.getPosition().lng(),
      })
      room.sendData({
        type: 'PosChanged',
        pos: {
          lat: panorama.getPosition().lat(),
          lng: panorama.getPosition().lng(),
        },
      })
    })

    return () => {
      google.maps.event.clearListeners(panorama, 'position_changed')
    }
  }, [])

  return (
    <div className='flex h-full w-full items-center justify-center'>
      <div id='map' className='h-full w-full' />
      <div id='pano' className='h-full w-full' />
    </div>
  )
}

export default HostMap

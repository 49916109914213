import {
  useContext,
  useState,
  useRef,
  useEffect,
  SetStateAction,
  Dispatch,
} from 'react'
import { Helpers } from '@api.stream/studio-kit'
import { FiSend } from 'react-icons/fi'
import { MdOutlineClose } from 'react-icons/md'
import { ChatMessage, Message } from './ChatMessage'
import { cn } from '../../utils/lib'

const { StudioContext } = Helpers.React

interface ChatViewProps {
  chatHistory: Message[]
  selfId: string
  onClose: any
  className?: string
  draft: string
  setDraft: Dispatch<SetStateAction<string>>
}

const ChatView = (props: ChatViewProps) => {
  const { chatHistory, selfId, onClose, draft, setDraft } = props
  const { room } = useContext(StudioContext)

  const scrollbox = useRef(null)

  useEffect(() => {
    if (chatHistory.length > 0) {
      scrollbox.current.scrollTo({
        top: scrollbox.current.scrollHeight,
      })
    }
  }, [chatHistory])

  const onSendMessage = () => {
    if (!draft.trim()) {
      return
    }
    room.sendChatMessage({
      message: draft.trim(),
    })
    setDraft('')
  }

  const onKeyPressChat = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      onSendMessage()
    }
  }

  return (
    <div
      className={cn(
        'flex w-full flex-col rounded-lg bg-base-100',
        props.className
      )}
    >
      {/* header / title bar */}
      <div className='mt-2 flex w-full pl-7 pr-2'>
        <h2 className='ml-auto mr-auto mt-2 justify-self-center text-center text-2xl font-extrabold'>
          Chatroom
        </h2>
        <button onClick={onClose}>
          <MdOutlineClose className='h-7 w-7' />
        </button>
      </div>

      {/* scrollbox */}
      <div
        ref={scrollbox}
        className='flex flex-grow flex-col overflow-auto px-6 pb-4'
      >
        {chatHistory.length === 0 && (
          <p className='mt-2 text-center text-sm font-normal text-base-content/70'>
            Private chat between host and guests.
          </p>
        )}
        {chatHistory.map((msg, i) => {
          return (
            <ChatMessage key={i} message={msg} isSelf={msg.sender === selfId} />
          )
        })}
      </div>
      <div className='flex items-center justify-between gap-3 rounded-b-lg border-t border-base-content/20 px-2'>
        <textarea
          className='flex-1 resize-y overflow-hidden rounded-md border-0 bg-base-100 p-4 font-normal focus:outline-none'
          placeholder='Write a message...'
          rows={1}
          value={draft}
          onChange={e => setDraft(e.target.value)}
          onKeyPress={onKeyPressChat}
        />
        <button
          className='btn-secondary btn-sm btn-circle btn'
          onClick={onSendMessage}
        >
          <FiSend size={18} />
        </button>
      </div>
    </div>
  )
}

export default ChatView

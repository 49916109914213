import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helpers, init } from '@api.stream/studio-kit'
import { setToken } from '../../store/actions/tokenActions'
import ProjectView from './ProjectView'
import { config } from '../../config/config'

const { ScenelessProject } = Helpers
const { useStudio } = Helpers.React

const displayName = 'HOST'

const HostView = ({ token, setToken }) => {
  const navigate = useNavigate()
  const {
    studio,
    project,
    projectCommands,
    room,
    setProject,
    setRoom,
    setStudio,
  } = useStudio()
  const [failure, setFailure] = useState(null)
  let backgroundImage =
    'https://www.coindesk.com/resizer/9KKmCHfJGOfXu8SWX1Q7w1fTBT0=/arc-photo-coindesk/arc2-prod/public/RORTMU2XOFHUPMCLZNYQ2UDOWI.jpg'

  // store as a global for debugging in console
  window.SDK = useStudio()

  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  }, [token, navigate])

  useEffect(() => {
    if (!token || studio) return

    init({
      env: config.env,
    })
      .then(setStudio)
      .catch(e => {
        console.warn('Failed to initialize', e)
        setFailure(e.message)
      })
  }, [token, studio, setStudio])

  useEffect(() => {
    if (!studio) return
    // if the SDK detects a token in the URL, it will return the project
    // associated with it (e.g. guest view)
    setProject(studio.initialProject)
  }, [studio, setProject])

  useEffect(() => {
    if (!token || !studio || project) {
      return
    }

    studio
      .load(token)
      .then(async user => {
        // if there's a project, return it - otherwise create one
        let project = user.projects[0]

        if (!project) {
          project = await ScenelessProject.create()
        }

        const activeProject = await studio.Command.setActiveProject({
          projectId: project.id,
        })
        const room = await activeProject.joinRoom({
          displayName,
        })
        setRoom(room)
        setProject(activeProject)
      })
      .catch(e => {
        console.warn(e)
        setToken(null)
      })
  }, [token, studio, project, setProject, setRoom, setToken])

  useEffect(() => {
    if (!room || !projectCommands) return

    const type = 'camera'
    projectCommands.addParticipant(
      room.participantId,
      { isMuted: true, noDisplay: true, isHidden: true, volume: 1 },
      type
    )
    projectCommands.setParticipantMuted(room.participantId, true)
    room.sendData({ type: 'UserJoined' })
    projectCommands.addImageOverlay('1', {
      src: backgroundImage,
    })
  }, [room, projectCommands, backgroundImage])

  useEffect(() => {
    if (projectCommands && room) {
      projectCommands.pruneParticipants()
    }
  }, [projectCommands, room])

  if (project && room) {
    return <ProjectView displayName={displayName} />
  }

  if (failure) {
    return <div>Failed to initialize: `{failure}`</div>
  }

  return (
    <div className='flex h-[80vh] w-full flex-col items-center justify-center'>
      <span className='loading-spinner-lg loading loading-spinner'></span>
      <p className='mt-6'>Loading tour room</p>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    token: state.token,
  }
}

const mapDispatchToProps = { setToken }

export default connect(mapStateToProps, mapDispatchToProps)(HostView)

import { useState, useEffect } from 'react'
import { MdOutlineClose } from 'react-icons/md'
import ParticipantItem from '../../components/participant/ParticipantItem'
import { Helpers } from '@api.stream/studio-kit'
import { cn } from '../../utils/lib'
import { Participant } from '@api.stream/studio-kit/types/src/core/types'

const { useStudio } = Helpers.React

interface ParticipantManagerProps {
  className?: string
  onClose: any
}

const ParticipantManager = ({
  className,
  onClose,
}: ParticipantManagerProps) => {
  const { room } = useStudio()
  const [participants, setParticipants] = useState<Participant[]>([])

  useEffect(() => {
    return room.useParticipants(setParticipants)
  }, [room])

  return (
    <div
      className={cn(
        'flex w-full flex-col rounded-lg bg-base-100 p-2',
        className
      )}
    >
      {/* header / title bar */}
      <div className='flex w-full pl-7'>
        <h2 className='ml-auto mr-auto mt-2 justify-self-center text-center text-2xl font-extrabold'>
          Participants
        </h2>
        <button onClick={onClose}>
          <MdOutlineClose className='h-7 w-7' />
        </button>
      </div>

      <div className='mt-6 overflow-auto px-3'>
        {participants.map(participant => (
          <ParticipantItem key={participant.id} participant={participant} />
        ))}
      </div>
    </div>
  )
}

export default ParticipantManager
